import "./styles/Navbar.css"

const Navbar = () => {
  return (
    <nav className="nav-container">
      <div className="section-container">
        <div className="nav">
          <a href="https://www.dioneprotocol.com/" className="logo">
            <img
              src="/Logo.svg"
              alt="Dione Protocol"
            />
          </a>
          {/* <button
            className="nav-btn"
            onClick={
              () => {
                window.open('https://github.com/dioneProtocol/faucet', '_blank')
              }
            }
          >
            <img alt='github' src="/github.webp"/>
            Contribute on Github
          </button> */}
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
